import React, { useMemo, memo } from 'react'
import { useDataProvider } from '../../hooks/useDataProvider';
import { CustomerWithMachine } from '../../models/Customers';
import { Table, TableHead, TableCell, TableBody, Chip, IconButton, TableRow } from '@mui/material';
import { FaUserEdit, FaSuitcase } from 'react-icons/fa';
import { customerModalOpen } from './CustomerDialog'
import { serviceHistoryModalOpen } from '../shared/ServiceHistoryListRender'
import { FormattedServiceCall, serviceCallDivisionDescription, serviceCallStatusDescription } from '../../models/ServiceCall';
import moment from 'moment';


type Props = {
    filter: string
}

function CustomerListRender({filter}: Props) {
    const {
      customers,
      machines,
      serviceCalls,
      users,
      customersLoading,
      machinesLoading,
      serviceCallsLoading,
    } = useDataProvider();

    const customerWithMachines: CustomerWithMachine[] = useMemo(() => {

        if (customers) {
    
          const macs = machines ?? []
    
          let filteredCustomers = customers;
    
          if (filter !== '') {
            filteredCustomers = customers.filter(c =>
              c.name.toLowerCase().indexOf(filter.toLocaleLowerCase()) > -1 ||
              c.contact_name?.toLowerCase().indexOf(filter.toLocaleLowerCase()) > -1 ||
              c.contact_email?.toLowerCase().indexOf(filter.toLocaleLowerCase()) > -1 ||
              c.contact_number?.toLowerCase().indexOf(filter.toLocaleLowerCase()) > -1
            );
          }
          
          return filteredCustomers.map(c => {
            return {
              ...c,
              machines: macs.filter(m => m.customer_id === c.id)
            }
          })    
        }
    
        else {
          return []
        }
       
      }, [customers, machines, filter])
    
      const listServiceHistory = useMemo(() => {
        if(!serviceCalls)
          return [] as FormattedServiceCall[]
        return serviceCalls?.map(sc =>{
          const machine = machines?.find(mac => mac.id === sc.machine_id);
  
          const customer = customers?.find(cs => cs.id === machine?.customer_id)
  
          const assignee = sc.assigned_to !== null ? users?.find(u => u.id === sc.assigned_to) : undefined;
  
          const fsc:FormattedServiceCall = {
            id: sc.id,
            assigned_to: assignee,
            created: moment(sc.created).toLocaleString(),
            time_since_creation: moment(sc.created).fromNow(),
            time_since_last_update: moment(sc.status_updated).fromNow(),
            created_by_email: sc.created_by?.email,
            created_by_name: sc.created_by?.name,
            description: sc.description,
            machine,
            customer,
            status: serviceCallStatusDescription(sc.status),
            call: sc
          }
          return fsc
        }).sort((left, right):number => {
          let result = 0;
  
          if (left.call.vip) {
            if (!right.call.vip) {
              return -1;
            } 
          }
          else {
            if (right.call.vip) {
              return 1;
            } 
          }
  
          result = moment(left.call.created).diff(moment(right.call.created))
  
          return result;
        })
      }, [serviceCalls, customers, machines, users])
      
      const pageLoading = useMemo(() => {
        return customersLoading || machinesLoading || serviceCallsLoading;
      }, [customersLoading, machinesLoading, serviceCallsLoading])

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Division</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Contact Info</TableCell>
            <TableCell>Machine(s)</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pageLoading && (
            <TableRow>
              <TableCell colSpan={5} className="text-center">
                Loading...
              </TableCell>
            </TableRow>
          )}
          {!pageLoading &&
            customerWithMachines.map((cm, i) => (
              <TableRow key={i}>
                <TableCell>{serviceCallDivisionDescription(cm.division)}</TableCell>
                <TableCell>
                  {cm.hold && <Chip label='On Hold' color='error' size='small' />}
                  <div>{cm.name}</div>
                </TableCell>
                <TableCell>
                  <div>{cm.contact_name}</div>
                  <div>Email: {cm.contact_email}</div>
                  <div>Phone: {cm.contact_number}</div>
                </TableCell>
                <TableCell>
                  {cm.machines?.map((m, j) => (
                    <div
                      style={{
                        padding: "0.5em",
                        backgroundColor: "#f3f3f3",
                        marginBottom: "1em",
                      }}
                      key={`machine-${i}-${j}`}
                    >
                      <div>
                        Contract:{" "}
                        <b style={{ color: m.contract ? "green" : "red" }}>
                          {m.contract ? "Yes" : "No"}
                        </b>
                      </div>
                      <div>Equipment Id: {m.external_id}</div>
                      <div>Serial: {m.serial}</div>
                      <div>Contract Level : {m.brand}</div>
                      <div>Contract Expiration: {m.make}</div>
                      <div>
                       {m.model}
                      </div>
                      <IconButton
                        aria-label="Edit"
                        onClick={() => {
                          serviceHistoryModalOpen(m.id ?? "", listServiceHistory);
                        }}
                      >
                        <FaSuitcase />
                      </IconButton>
                    </div>
                  ))}
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="Edit"
                    onClick={() => {
                      customerModalOpen(cm);
                    }}
                  >
                    <FaUserEdit />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  )
}

export default memo(CustomerListRender)