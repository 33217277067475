import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useAxios } from '../../hooks/useAxios';
import { CallNote, emptyCallNote } from '../../models/ServiceCall';
import { ApiResponse } from '../../models/ApiResponse';
import moment from 'moment';
import { FaPlusCircle } from 'react-icons/fa';

type Props = {
    callid: string;
}

function NotesComponent({ callid }: Props) {
    
    const axios = useAxios();

    const [notes, setNotes] = useState<CallNote[]|undefined>(undefined);
    const [newNote, setNewNote] = useState("");
    const [loading, setLoading] = useState(false);

    const fetchNotes = useCallback(() => {
        axios.get<ApiResponse<CallNote[]>>(`calls/notes?id=${callid}`)
        .then(res => {
            const apiResponse = res.data;
            const notes = apiResponse.data;
            setNotes(notes);
        })

    },[axios, callid, setNotes])

    useMemo(() => {
        if (!notes) {
            fetchNotes();
        }
       
    }, [fetchNotes, notes]);

    const addNote = useCallback((str: string) => {
        const note = emptyCallNote(str, callid);

        setLoading(true);

        axios.post<ApiResponse<CallNote[]>>(`calls/addnote`, JSON.stringify(note))
        .then(res => {
            const apiResponse = res.data;
            alert(apiResponse.message);
        })
        .finally(() => {
            setLoading(false);
            fetchNotes();
            setNewNote('');
        })
    },[axios, setLoading, setNewNote, fetchNotes, callid])


    return (
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel11a-content"
          id="panel11a-header"
        >
          <Typography>Notes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} sx={{ m: 2 }}>
            <Grid item sm={12} md={8}>
              <TextField
                multiline
                value={newNote}
                label="Add Note"
                fullWidth
                rows={5}
                onChange={(e) => {
                  setNewNote(e.target.value);
                }}
                disabled={loading}
              />
            </Grid>
            <Grid item sm={12} md={2}>
              <Button
                onClick={(e) => {
                  addNote(newNote);
                }}
                disabled={loading}
                variant="contained"
              >
                <FaPlusCircle style={{marginRight: '1em'}} /> Note
              </Button>
            </Grid>
          </Grid>
          <Divider />
          {notes?.map(note => (
            <Paper key={note.id} sx={{ m: 1, p: 1 }}>
                <div><b style={{fontSize: 'larger'}}>{note.user?.email}</b> ({moment(note.created).toLocaleString()})</div>
                <p style={{padding: '0.5em'}}>{note.note}</p>
            </Paper>
          ))}
        </AccordionDetails>
      </Accordion>
    );
}

export default NotesComponent