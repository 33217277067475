import { CheckBox, ExitToApp } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  TextField,
  Toolbar,
  Typography,
  Button,
  InputLabel,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  Select,
  NativeSelect,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import { Container } from "@mui/system";
import { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ServiceCallControl from "../../components/ServiceCallControl";
import { useAuth } from "../../hooks/useAuth";
import { useDataProvider } from "../../hooks/useDataProvider";
import {
  SERVICE_CALL_STATUS_ENUM,
  CLOSE_STATUS_CHECK,
  serviceCallStatusDescription,
  FormattedServiceCall,
} from "../../models/ServiceCall";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";

type Props = {};

function Tech({}: Props) {
  const navigate = useNavigate();
  const { setAuth, auth } = useAuth();

  const data = useDataProvider();
  const current_tech = auth?.guid;
  const current_tech_email = auth?.email;

  const defaultStatuses = [
    ...Array.from(Array(SERVICE_CALL_STATUS_ENUM.length).keys()),
  ].filter((x) => !CLOSE_STATUS_CHECK(x));
  const closedStatuses = [
    ...Array.from(Array(SERVICE_CALL_STATUS_ENUM.length).keys()),
  ].filter((x) => CLOSE_STATUS_CHECK(x));
  const partsStatuses = [4, 6, 7];

  // add states for filters here
  const [machineIdFilter, setmachineIdFilter] = useState("");
  const [statusFilters, setStatusFilter] = useState<number[]>(defaultStatuses);
  // const [textFilter, setTextFilter] = useState('');
  const [serialFilter, setSerialFilter] = useState("");
  const [modelFilter, setModelFilter] = useState("");
  const [customerFilter, setCustomerFilter] = useState("");
  const [workOrderNumberFilter, setWorkOrderNumberFilter] = useState("");

  const [techFilter, setTechFilter] = useState<string[]>([]);

  const [startDate, setStartDate] = useState(data.callsFilter?.start);
  const [endDate, setEndDate] = useState(data.callsFilter?.end);
  const beginningoffss = moment()
    .year(2023)
    .month(0)
    .date(1)
    .format("YYYY-MM-DD"); //months are zero indexed
  const todaysDate = moment().format("YYYY-MM-DD");

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        // width: 250,
      },
    },
  };

  const handleStatusFilterChange = (
    event: SelectChangeEvent<typeof statusFilters>
  ) => {
    const {
      target: { value },
    } = event;
    setStatusFilter(
      // On autofill we get a stringified value.
      typeof value === "string" ? [parseInt(value)] : value
    );
  };

  // const allServiceHistory = useMemo(() => {
  //   return data.formattedServiceCalls?.slice()
  // },[data]);

  const svclist = useMemo(() => {
    //create shallow copy
    let res = data.formattedServiceCalls
      ?.slice()
      ?.filter(
        (c) =>
          c.assigned_to?.id === current_tech ||
          c.assigned_to?.email === current_tech_email
      );

    if (machineIdFilter !== "") {
      res = res?.filter((r) => {
        if (r.machine?.external_id === undefined) return false;
        return r.machine?.external_id === machineIdFilter;
      });
    }

    if (techFilter.length > 0) {
      res = res?.filter((r) => {
        if (r.assigned_to?.id === undefined) return false;
        return techFilter.includes(r.assigned_to?.id as string);
      });
    }

    if (statusFilters.length > 0) {
      res = res?.filter((c) => {
        if (c.call.status === undefined) return false;
        return statusFilters.includes(c.call.status);
      });
    }

    if (serialFilter !== "") {
      const lfilter = serialFilter.toLowerCase();

      res = res?.filter((c) => {
        if (c.machine?.serial === undefined) return false;
        return c.machine?.serial.toLowerCase().includes(lfilter);
      });
    }

    if (modelFilter !== "") {
      const lfilter = modelFilter.toLowerCase();

      res = res?.filter((c) => {
        if (c.machine?.model === undefined) return false;
        return c.machine?.model.toLowerCase().includes(lfilter);
      });
    }

    if (customerFilter !== "") {
      const lfilter = customerFilter.toLowerCase();

      res = res?.filter((c) => {
        if (c.customer?.name === undefined) return false;
        return c.customer?.name.toLowerCase().includes(lfilter);
      });
    }

    if (workOrderNumberFilter !== "") {
      const lowerCaseWorkOrderNumberFilter =
        workOrderNumberFilter.toLowerCase();

      res = res?.filter((c) => {
        if (c.call.external_id === undefined) return false;
        return c.call.external_id
          ?.toLowerCase()
          .includes(lowerCaseWorkOrderNumberFilter);
      });
    }

    const val = res ?? [];

    return val;
  }, [
    data.formattedServiceCalls,
    machineIdFilter,
    techFilter,
    statusFilters,
    serialFilter,
    modelFilter,
    customerFilter,
    workOrderNumberFilter,
    current_tech,
    current_tech_email,
  ]);

  //added by art
  const [expandedItemId, setExpandedItemId] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedItemId(isExpanded ? panel : false);
    };

  return (
    <>
      <AppBar position="absolute">
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Field Service System
          </Typography>
          <IconButton
            color="inherit"
            onClick={(e) => {
              if (setAuth) {
                setAuth({ guid: "", token: "", email: "" });
              }
              navigate("/", { replace: true });
            }}
          >
            <ExitToApp />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Container fixed sx={{ pt: 10 }} maxWidth="lg">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <b>Filters</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item sm={4}>
                <TextField
                  fullWidth
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    data.setCallsFilter({
                      ...data.callsFilter,
                      start: e.target.value,
                    });
                  }}
                  type="date"
                  label="Start Date"
                  value={startDate}
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  fullWidth
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    data.setCallsFilter({
                      ...data.callsFilter,
                      end: e.target.value,
                    });
                  }}
                  type="date"
                  label="End Date"
                  value={endDate}
                />
              </Grid>
              <Grid item sm={4}>
                <Button
                  disabled={data.serviceCallsLoading}
                  onClick={() => {
                    data.fetchServiceCalls();
                  }}
                  fullWidth
                  variant="contained"
                >
                  Apply
                </Button>
              </Grid>
              <Grid item sm={12}>
                <hr />
                <h2>
                  <b>Search by:</b>
                </h2>
                <br />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  name="textfilter"
                  value={workOrderNumberFilter}
                  label="Work Order Number"
                  onChange={(e) => {
                    setWorkOrderNumberFilter(e.target.value);
                  }}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  name="textfilter"
                  value={modelFilter}
                  label="Printer Model"
                  onChange={(e) => {
                    setModelFilter(e.target.value);
                  }}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  name="textfilter"
                  value={serialFilter}
                  label="Serial Number"
                  onChange={(e) => {
                    setSerialFilter(e.target.value);
                  }}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  name="textfilter"
                  value={customerFilter}
                  label="Customer Name"
                  onChange={(e) => {
                    setCustomerFilter(e.target.value);
                  }}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item sm={4}>
                <TextField
                  name="filter"
                  value={machineIdFilter}
                  label="Equipment Id"
                  onChange={(e) => {
                    setmachineIdFilter(e.target.value);
                  }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <hr />
                <FormControl fullWidth>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Statuses
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={statusFilters}
                    onChange={handleStatusFilterChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) =>
                      selected
                        .map((s) => serviceCallStatusDescription(s))
                        .join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    {SERVICE_CALL_STATUS_ENUM.map((name, i) => (
                      <MenuItem key={name} value={i}>
                        <Checkbox checked={statusFilters.indexOf(i) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <hr />
                <h2>
                  <b>Quick Filters</b>
                </h2>
                <br />
                <Button
                  sx={{ mt: 1, ml: 1 }}
                  onClick={(e) => {
                    setStatusFilter([]);
                  }}
                  variant="contained"
                  color="secondary"
                >
                  All Calls
                </Button>
                <Button
                  sx={{ mt: 1, ml: 1 }}
                  onClick={(e) => {
                    setStatusFilter(defaultStatuses);
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Current Calls
                </Button>
                <Button
                  sx={{ mt: 1, ml: 1 }}
                  onClick={(e) => {
                    setStatusFilter(closedStatuses);
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Closed Calls
                </Button>
                <Button
                  sx={{ mt: 1, ml: 1 }}
                  onClick={(e) => {
                    setStatusFilter(partsStatuses);
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Parts
                </Button>
              </Grid>
              <Grid item sm={12}>
                <hr />
              </Grid>
              <Grid item sm={12}>
                <Button
                  sx={{ mt: 1 }}
                  onClick={(e) => {
                    setmachineIdFilter("");
                    setStatusFilter(defaultStatuses);
                    // setTextFilter("");
                    setSerialFilter("");
                    setModelFilter("");
                    setCustomerFilter("");
                    setTechFilter([]);
                    setStartDate(beginningoffss);
                    setEndDate(todaysDate);
                  }}
                  variant="contained"
                >
                  Reset All Filters
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Divider />
        {data.customersLoading &&
          data.machinesLoading &&
          data.serviceCallsLoading &&
          data.usersLoading && <>Loading...</>}
        {!data.customersLoading &&
          !data.machinesLoading &&
          !data.serviceCallsLoading &&
          !data.usersLoading &&
          svclist
            ?.slice(0)
            .reverse()
            .map((dr, i) => (
              /*accordion UI starts here*/
              <Accordion
                key={i}
                expanded={expandedItemId === `panel${dr.id}`}
                onChange={handleChange(`panel${dr.id}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    <b>
                      {dr.call.external_id} - {dr.customer?.name}
                    </b>
                    <br />
                    {dr.machine?.city} {dr.machine?.zip}
                    <span
                      style={{
                        color:
                          moment().diff(moment(dr.call?.created), "days") >= 5
                            ? "red"
                            : "inherit", //mark pending calls 10 days or older
                      }}
                    >
                      <br />
                      {new Date(dr.created).toLocaleDateString("en-US", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })} <b>{dr.status}</b> ({dr.time_since_last_update})
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ServiceCallControl key={i} call={dr} />
                </AccordionDetails>
              </Accordion>
            ))}{" "}
        {/*allCalls={allServiceHistory ?? [] as FormattedServiceCall[]} */}
      </Container>
    </>
  );
}

export default Tech;
