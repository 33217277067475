import { Button, Card, CardActions, CardContent, Divider, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useAxios } from '../../hooks/useAxios';
import { ApiResponse } from '../../models/ApiResponse';
import { emptyMachine, Machine } from '../../models/Machine'

type Props = {
    machine?: Machine
    customerId?: string
    cancelAdd: (id?: string) => void
}

function MachineView(prop: Props) {

  const [isEdit, setIsEdit] = useState(false);
  const [sending, setSending] = useState(false);

  const _machine = prop.machine ?? emptyMachine(prop.customerId || '');

  const [machine, setMachine] = useState(_machine);

  useEffect(() => {
    setMachine(_machine);
  }, [_machine])

  const axios = useAxios();

  useEffect(() => {
    if (!machine.id) {
        setIsEdit(true);
    }
  }, [machine])


  return (
    <div style={{ display: "block", margin: "1em" }}>
      <Card>
        <CardContent>
          <form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item md={6} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({ ...machine, serial: e.target.value || "" });
                  }}
                  value={machine.serial}
                  label="Serial #"
                  disabled={!isEdit}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({
                      ...machine,
                      external_id: e.target.value || "",
                    });
                  }}
                  value={machine.external_id}
                  label="Equipment Id"
                  disabled={!isEdit}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({ ...machine, brand: e.target.value || "" });
                  }}
                  value={machine.brand}
                  label="Contract Level"
                  disabled={!isEdit}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({ ...machine, make: e.target.value || "" });
                  }}
                  value={machine.make}
                  label="Contract Expiration"
                  disabled={!isEdit}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({ ...machine, model: e.target.value || "" });
                  }}
                  value={machine.model}
                  label="Model"
                  disabled={!isEdit}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <FormControlLabel
                    control={
                      <Switch
                        onChange={(e) => {
                          setMachine({ ...machine, contract: e.target.checked });
                        }}
                        checked={machine.contract}
                      />
                    }
                    label="Under Contract?"
                  />
              </Grid>
              <Divider />
              <Grid item md={6} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({
                      ...machine,
                      contact_name: e.target.value || "",
                    });
                  }}
                  value={machine.contact_name}
                  label="Contact Name"
                  disabled={!isEdit}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({
                      ...machine,
                      contact_number: e.target.value || "",
                    });
                  }}
                  value={machine.contact_number}
                  label="Contact Number"
                  disabled={!isEdit}
                />
              </Grid>
              <Grid item md={6} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({
                      ...machine,
                      address: e.target.value || "",
                    });
                  }}
                  value={machine.address}
                  label="Address"
                  disabled={!isEdit}
                />
              </Grid>
              <Grid item md={4} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({
                      ...machine,
                      city: e.target.value || "",
                    });
                  }}
                  value={machine.city}
                  label="City"
                  disabled={!isEdit}
                />
              </Grid>
              <Grid item md={4} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({
                      ...machine,
                      state: e.target.value || "",
                    });
                  }}
                  value={machine.state}
                  label="State"
                  disabled={!isEdit}
                />
              </Grid>
              <Grid item md={4} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({
                      ...machine,
                      zip: e.target.value || "",
                    });
                  }}
                  value={machine.zip}
                  label="Zip"
                  disabled={!isEdit}
                />
              </Grid>
              <Grid item md={4} sm={12}>
                <TextField
                  fullWidth
                  sx={{ mb: 1 }}
                  type="text"
                  autoComplete="off"
                  onChange={(e) => {
                    setMachine({
                      ...machine,
                      onsite_location: e.target.value || "",
                    });
                  }}
                  value={machine.onsite_location}
                  label="Onsite Location"
                  disabled={!isEdit}
                />
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions>
          {isEdit && (
            <>
              <Button
                disabled={sending}
                size="small"
                variant="contained"
                color="success"
                onClick={() => {
                  setSending(true);

                  axios
                    .post<ApiResponse<Machine>>(
                      "machines/addorupdate",
                      JSON.stringify(machine)
                    )
                    .then((res) => {
                      const data = res.data;
                      alert(data.message);
                      setIsEdit(false);
                    })
                    .finally(() => {
                      setSending(false);
                    });
                }}
              >
                Save
              </Button>
              <Button
                disabled={sending}
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                  setIsEdit(false);
                  if (!machine.id) {
                    prop.cancelAdd();
                  }
                }}
              >
                Cancel
              </Button>
            </>
          )}
          {!isEdit && (
            <>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => setIsEdit(true)}
                disabled={sending}
              >
                Edit
              </Button>
              <Button
                disabled={sending}
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals
                  if (confirm("Delete this machine?")) {
                    setSending(true);

                    axios
                      .delete<ApiResponse<Machine>>(
                        `machines?machineId=${machine.id}`
                      )
                      .then((res) => {
                        const data = res.data;
                        alert(data.message);
                        prop.cancelAdd(machine.id);
                      })
                      .finally(() => {
                        setSending(false);
                      });
                  }
                }}
              >
                Delete
              </Button>
            </>
          )}
        </CardActions>
      </Card>
    </div>
  );
}

export default MachineView